import React from 'react'
import strings from '../../utils/locals/languages';
import { toast } from 'react-toastify';

const VehicleAds = ({
    vehicleData,
    user,
    sortBy,
    navigate,
    Dropdown,
    dot,
    Cookies,
    setShowModal,
    setDeletingItemId,
    beds,
    area,
    wash,
    status,
    year,
    engineSize,
    calendar
}) => {
    return (
        <div className="contai mx-auto mt-4">
            <div className="container ">
                <h2 className="uppercase font-semibold text-base">
                    {strings.vehicles}
                </h2>
                <div>
                    {vehicleData?.length === 0 ? (
                        <div className="flex justify-center">
                            <h3>{strings.norec}</h3>
                        </div>
                    ) : (
                        <div className="grid grid-cols-1  md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 gap-5 justify-center">
                            {vehicleData
                                ?.filter((object) => ((object?.user?._id === user?._id) && (object.status === status)))
                                .sort((a, b) => {
                                    if (sortBy === "ph") {
                                        return (
                                            b.basicInformation.price.price -
                                            a.basicInformation.price.price
                                        ); // Sort by highest price
                                    } else if (sortBy === "pl") {
                                        return (
                                            a.basicInformation.price.price -
                                            b.basicInformation.price.price
                                        ); // Sort by lowest price
                                    } else if (sortBy === "atoz") {
                                        return a.basicInformation.title.localeCompare(
                                            b.basicInformation.title
                                        ); // Sort A to Z
                                    } else if (sortBy === "ztoa") {
                                        return b.basicInformation.title.localeCompare(
                                            a.basicInformation.title
                                        ); // Sort Z to A
                                    } else if (sortBy === "new") {
                                        return (
                                            new Date(b.createdAt) - new Date(a.createdAt)
                                        ); // Sort by newest createdAt
                                    } else if (sortBy === "old") {
                                        return (
                                            new Date(a.createdAt) - new Date(b.createdAt)
                                        ); // Sort by oldest createdAt
                                    } else {
                                        // Handle other sorting options if needed
                                        return 0; // No sorting
                                    }
                                })
                                .map((item, index) => {
                                    let isDropdownToggleClicked = false;
                                    return (
                                        <div
                                            className="ca rounded-xl shadow-md border-none cursor-pointer overflow-hidden -mb-5 w "
                                            onClick={() => {
                                                if (!isDropdownToggleClicked) {
                                                    navigate(`/vehicle-details/${item._id}`, {
                                                        state: {
                                                            details: item,
                                                        },
                                                    });
                                                }
                                            }}
                                        >
                                            <div
                                                className="relative flex h-40"
                                            >
                                                <img
                                                    className="mb r"
                                                    src={item.coverPhoto}
                                                    alt="random"
                                                    style={{
                                                        height: "100%",
                                                        width: "100%",
                                                        objectFit: "cover",
                                                        // borderTopRightRadius: 12,
                                                        // borderTopLeftRadius: 12,
                                                    }}
                                                />
                                                <Dropdown className="bg-none h-96">
                                                    <Dropdown.Toggle
                                                        style={{
                                                            position: "absolute",
                                                            top: "5px",
                                                            right: "0",
                                                            zIndex: "1",
                                                            background: "none",
                                                            border: "none",
                                                            height: "50px",
                                                            width: "50px",
                                                        }}
                                                    >
                                                        <img
                                                            // className="absolute items-content-end py-10"
                                                            src={dot}
                                                            alt=""
                                                            onClick={() => {
                                                                isDropdownToggleClicked = true; // Set the flag when the dropdown toggle is clicked
                                                            }}
                                                            onMouseLeave={() => {
                                                                isDropdownToggleClicked = false; // Reset the flag when the mouse leaves the dropdown toggle
                                                            }}
                                                        />
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu
                                                        // style={{ maxHeight: "70px", maxWidth: "40px", fontSize: "8px", paddingLeft: "1px", top: 5, right: 5 }}
                                                        style={{
                                                            maxHeight: "80px",
                                                            width: "120px", // Set the width to make it smaller
                                                            // position: "absolute",
                                                            fontSize: "8px",
                                                            right: "-20px", // Adjust the right value to shift it to the right
                                                        }}
                                                    >
                                                        {" "}
                                                        {/* Set the maxHeight to make it smaller */}
                                                        <Dropdown.Item
                                                            onClick={() => {
                                                                if(strings._language !== item.lango) {
                                                                    toast.warn(`${strings.ThisAdIsCreatedIn} ${item.lango === 'en' ? 'English' : 
                                                                        item.lango === 'ru' ? 'Russian' : 'Uzbek'} ${strings.PleaseChangeYourLanguageTo}
                                                                        ${item.lango === 'en' ? 'English' : 
                                                                            item.lango === 'ru' ? 'Russian' : 'Uzbek'
                                                                        }, ${strings.thenYouCanEdit}
                                                                        `)
                                                                } else {
                                                                    navigate("/edit-vehicle", {
                                                                        state: {
                                                                            data: item,
                                                                            newAdd: false,
                                                                            basicInfo: {
                                                                                category:
                                                                                    item.basicInformation.category
                                                                                        .title,
                                                                                brand:
                                                                                    item.basicInformation.brand
                                                                                        .title,
                                                                                model:
                                                                                    item.basicInformation.model
                                                                                        .title,
    
                                                                            },
                                                                            isEditOrCreate: "edit"
                                                                        },
                                                                    });
                                                                }

                                                                isDropdownToggleClicked = true;
                                                            }}
                                                            onMouseLeave={() => {
                                                                isDropdownToggleClicked = false; // Reset the flag when the mouse leaves the dropdown toggle
                                                            }}
                                                        >
                                                            {strings.editAd}
                                                        </Dropdown.Item>
                                                        <Dropdown.Item
                                                            onClick={() => {
                                                                setShowModal(true);
                                                                setDeletingItemId(item._id);
                                                                isDropdownToggleClicked = true;
                                                            }}
                                                            onMouseLeave={() => {
                                                                isDropdownToggleClicked = false; // Reset the flag when the mouse leaves the dropdown toggle
                                                            }}
                                                        >
                                                            {strings.delete}
                                                        </Dropdown.Item>
                                                        <Dropdown.Item
                                                            onClick={() => {
                                                                setShowModal(true);
                                                                setDeletingItemId(item._id);
                                                                isDropdownToggleClicked = true;
                                                            }}
                                                            onMouseLeave={() => {
                                                                isDropdownToggleClicked = false; // Reset the flag when the mouse leaves the dropdown toggle
                                                            }}
                                                        >
                                                            {strings.enableDisable}
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                            <div
                                                className="px-[10px] mt-1"
                                            // style={{ height: "120px" }}
                                            >
                                                <div
                                                    className="flex items-center justify-between -mb-2"
                                                // style={{ height: "30%" }}
                                                >
                                                    <div>
                                                        <h3
                                                            className="font-[500] "
                                                            style={{
                                                                fontSize: "12px",
                                                                fontWeight: 600,
                                                            }}
                                                        >
                                                            {item.basicInformation.title.slice(
                                                                0,
                                                                12
                                                            )}
                                                        </h3>
                                                    </div>
                                                    <div
                                                        className="flex "
                                                        style={{
                                                            color: "#F37521",
                                                            fontSize: "12px",
                                                        }}
                                                    >
                                                        <h1 className="font-bold text-sm ">
                                                            {item.basicInformation.price.currency}&nbsp;
                                                        </h1>
                                                        <h1 className="font-bold text-sm ">
                                                            {item.basicInformation.price.price}
                                                        </h1>
                                                    </div>
                                                </div>
                                                <div
                                                    className="flex gap-2 -mb-3"
                                                // style={{ height: "30%" }}
                                                >
                                                    <h1 className="text-[9px] font-[350] text-gray-500 ">
                                                        {item.basicInformation.description.slice(
                                                            0,
                                                            15
                                                        )}
                                                    </h1>
                                                </div>
                                                <div
                                                    className="flex -mb-1 text-[10px] justify-between items-center text-gray-400"
                                                // style={{ height: "40%" }}
                                                >
                                                    <div className="flex gap-2 items-center">
                                                        <img
                                                            src={year}
                                                            alt="year"
                                                            className="h-3 w-3"
                                                        />
                                                        <p className="text pt-3">
                                                            {item.basicInformation.details[0].value}
                                                        </p>
                                                    </div>

                                                    <div className="flex gap-1 items-center">
                                                        <img
                                                            src={engineSize}
                                                            alt="year"
                                                            className="h-3 w-3"
                                                        />
                                                        <p className="text pt-3">
                                                            {item.basicInformation.details
                                                                .filter((item) =>
                                                                    [
                                                                        "Engine Size",
                                                                        "Размер двигателя",
                                                                        "Dvigatel hajmi",
                                                                    ].includes(item.fieldName)
                                                                )
                                                                .map((filteredItem, index) => (
                                                                    <span key={index}>
                                                                        {filteredItem.value}
                                                                    </span>
                                                                ))}
                                                        </p>
                                                    </div>

                                                    <div className="flex gap-1 items-center">
                                                        <img
                                                            src={calendar}
                                                            alt="year"
                                                            className="h-3 w-3"
                                                        />
                                                        <p className="text pt-3">
                                                            {item.basicInformation.KM}{" "}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default VehicleAds