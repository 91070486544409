import { useState, useEffect, useRef } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import "bootstrap/dist/css/bootstrap.min.css";
import logoImage from "../images/logo.svg";
import NavbarImage1 from "../images/Group 11680.png";
import NavbarImage2 from "../images/Group 11819.png";
import textnumber from "../images/textnumber.svg";
import Lock2 from "../images/Lock2.svg";
import loginmessage from "../images/loginmessage.svg";
import locationIcon from "../images/locationIcon.svg";
import profile from "../images/profile.png";
import Modal from "react-bootstrap/Modal";
import Verfiy from "../images/Verfiy.png";
import Successfuly from "../images/successfuly.svg";
import Lock from "../images/Lock.svg";
import Verify from "../images/Verfiy.png";
import ResetPassword from "../images/Resetpassword.svg";
import PasswordUpdate from "../images/passwordupdate.svg";
import { Link } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Login from "./Login";
import { useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import { baseURL1, uploadDataUrl } from "../utils/baseUrl";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import strings from "../utils/locals/languages";
import { setLanguage } from "../app/languageSlice";
import { useDispatch, useSelector } from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";
import { toast } from "react-toastify";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import apiClient from "../utils/apiClient";
import phoneIcon from "../images/phone.svg";
import { getAuth } from "firebase/auth";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import Avatar from "@mui/material/Avatar";
import "./Header.css";
import {
  getCountries,
  getCountryCallingCode,
} from "react-phone-number-input/input";
import en from "react-phone-number-input/locale/en";
import mailic from "../images/mailic.png";
import { app } from "../firebase";
import "react-loading-skeleton/dist/skeleton.css";
import EnglishFlag from '../images/english.png'
import RussianFlag from '../images/russian.png'
import uzbekFlag from '../images/uzbek.png'
import { setNotifications } from "../app/notificationsSlice";

const CountrySelect = ({ value, onChange, labels, ...rest }) => (
  <select
    {...rest}
    value={value}
    onChange={(event) => onChange(event.target.value || undefined)}
  >
    <option value="">{labels["ZZ"]}</option>
    {getCountries().map((country) => (
      <option key={country} value={getCountryCallingCode(country)}>
        {labels[country]} +{getCountryCallingCode(country)}
      </option>
    ))}
  </select>
);

const AppBar = ({ id, createPage, disableLanguage }) => {
  const [country, setCountry] = useState();
  const [profileData, setProfileData] = useState({});
  const inputRefs = [useRef(), useRef(), useRef(), useRef()];
  const [phoneOtpCode, setPhoneOtpCode] = useState("");
  const [phoneLoginLoader, setPhoneLoginLoader] = useState(false);
  const [signUpLoader, setSignUpLoader] = useState(false);
  const [userVerificationLoader, setUserVerificationLoader] = useState(false);
  const [passwordUpdatedSuccess, setPasswordUpdatedSuccess] = useState(false);
  const [phoneSignUpVerifyCodeScreen, setPhoneSignUpVerifyCodeScreen] =
    useState(false);
  const [emailVerificationCode, setEmailVerificationCode] = useState("");
  const [brands, setBrands] = useState([]);
  const [realEstateBrands, setRealEstateBrands] = useState([]);
  const [menu1Open, setMenu1Open] = useState(false);
  const button1Ref = useRef(null);
  const [phoneLoginOtpScreen, setPhoneLoginOtpScreen] = useState(false);
  const [timer, setTimer] = useState(30);
  const [resendEmailLoader, setResendEmailLoader] = useState(false)

  useEffect(() => {
    if (timer > 0) {
      const intervalId = setInterval(() => {
        setTimer(prevTime => prevTime - 1);
      }, 1000);

      // Cleanup interval on component unmount
      return () => clearInterval(intervalId);
    }
  }, [timer]);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  };


  const handleMenu1Open = (event) => {
    setMenu1Open(true);
  };
  const handleMenu1Close = () => {
    setMenu1Open(false);
  };

  const handlePhoneCode = (e) => {
    setCountry(e);
    setPhone(e);
  };

  const handlePhoneChange = (e) => {
    const value = e.target.value;
    setPhone(value);
  };

  const handleInput = (event, index) => {
    const input = inputRefs[index];
    const value = event.target.value;

    if (event.target.value.length >= 1) {
      if (index < inputRefs.length - 1) {
        inputRefs[index + 1].current.focus();
      }
    } else if (index > 0) {
      inputRefs[index - 1].current.focus();
    }

    // Update the otpValue state with the new input value
    const updatedOtpValue =
      phoneOtpCode.slice(0, index) + value + phoneOtpCode.slice(index + 1);
    setPhoneOtpCode(updatedOtpValue);
  };

  const handlePaste = (event) => {
    event.preventDefault();
    const pasteData = event.clipboardData.getData("text/plain").trim();
    if (/^\d{1,4}$/.test(pasteData)) {
      for (let i = 0; i < pasteData.length && i < inputRefs.length; i++) {
        inputRefs[i].current.value = pasteData[i];
        if (i < inputRefs.length - 1) {
          inputRefs[i + 1].current.focus();
        }
      }
    }
  };

  const handlePhoneVerificationCode = (e) => {
    e.preventDefault();
    if (!phoneOtpCode) {
      toast.error(`${strings.verificationCodeRequired}`);
    } else {
      setMobileResetPassword(true);
      setEnterTheCode(false);
      setPasswordUpdatedSuccess(false);
      setPhoneSignUpVerifyCodeScreen(false);
      setResetPassword(false);
      setForgotEmailOtpScreen(false);
      setSignInVerify(false);
      setSignUpForm(false);
      setForgotData(false);
      setEmailSignUpOtpScreen(false);
      setEmailSignInForm(false);
      setSignInWithNumber(false);
      setSignInWithLogin(false);
      setRecoverWithEmail(false);
      setMobileNumber(false);
      setPhoneLoginOtpScreen(false);
      setSignUpWithPhone(false);
    }
  };

  const hiddenFileInput = useRef(null);

  const handleFileClick = (file) => {
    hiddenFileInput.current.click();
  };

  const handleLogoUpload = (file) => {
    const formData = new FormData();
    formData.append("files", file);

    axios
      .post(`${uploadDataUrl}/v1/adds/upload-multiple`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        setLogo(res.data[0]);
        toast.success(`${strings.imageUpload}`);
      })
      .catch((err) => {
        toast.error(`${strings.imageUploadFailed}`);
      });
  };

  const dispatch = useDispatch();
  const [isSectionsLoading, setIsSectionsLoading] = useState(true);
  const token = Cookies.get("token");
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState("");
  const [forgotEmailEntry, setForgotEmailEntry] = useState("");
  const [forgotEmailLoader, setForgotEmailLoader] = useState(false);
  const [emailResetCode, setEmailResetCode] = useState("");
  const [emailResetLoader, setEmailResetLoader] = useState(false);
  const [resetPasswordLoader, setResetPasswordLoader] = useState(false);
  const [requestedPhoneNumber, setRequestedPhoneNumber] = useState("");
  const [emailSignUpOtpScreen, setEmailSignUpOtpScreen] = useState(false);

  const sendPhoneCodeRequest = (event) => {
    if (!requestedPhoneNumber) {
      toast.error(`${strings.phoneIsRequired}`);
    } else {
      setEnterTheCode(true);
      setPasswordUpdatedSuccess(false);
      setPhoneSignUpVerifyCodeScreen(false);
      setResetPassword(false);
      setForgotEmailOtpScreen(false);
      setSignInVerify(false);
      setSignUpForm(false);
      setForgotData(false);
      setEmailSignUpOtpScreen(false);
      setEmailSignInForm(false);
      setSignInWithNumber(false);
      setSignInWithLogin(false);
      setRecoverWithEmail(false);
      setMobileNumber(false);
      setMobileResetPassword(false);
      setPhoneLoginOtpScreen(false);
      setSignUpWithPhone(false);
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const logOutUser = () => {
    Cookies.remove("token");
    Cookies.remove("userDetails");
    toast.success("Logout Successfully");

    setTimeout(() => {
      window.location.reload();
    }, 500);
  };
  const [show, setShow] = useState(false);

  const handleLanguageChange = (language) => {
    strings.setLanguage(language)

    Cookies.set("language", language);
    setTimeout(() => {
      window.location.reload();
    }, 500);
    dispatch(setLanguage(language));
  };

  useEffect(() => {
    const selectedLanguage = Cookies.get("language");

    if (selectedLanguage !== undefined) {
      strings.setLanguage(selectedLanguage);
    }
  }, []);
  const handleClose = () => {
    setShow(false);
    setEmailSignInForm(true);

    setSignUpForm(false);
    setForgotData(false);
    setEmailSignUpOtpScreen(false);

    setSignInWithNumber(false);
    setSignInVerify(false);
    setRecoverWithEmail(false);
    setForgotEmailOtpScreen(false);
    setResetPassword(false);
    setPasswordUpdatedSuccess(false);
    setPhoneSignUpVerifyCodeScreen(false);
    setMobileNumber(false);
    setEnterTheCode(false);
    setMobileResetPassword(false);
    setSignUpEmailAddress(false);
    setPhoneLoginOtpScreen(false);
    setSignUpWithPhone(false);
    setVerificationSuccessScreen(false);
  };

  const handleShow = () => setShow(true);
  const [isLocked, setIsLocked] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [signUpForm, setSignUpForm] = useState(false);
  const [emailSignInForm, setEmailSignInForm] = useState(true);
  const [signinwithnumber, setSignInWithNumber] = useState(false);
  const [signinwithlogin, setSignInWithLogin] = useState(false);
  const [signinverify, setSignInVerify] = useState(false);
  const [recoverWithEmail, setRecoverWithEmail] = useState(false);
  const [forgotEmailOtpScreen, setForgotEmailOtpScreen] = useState(false);
  const [resetpassword, setResetPassword] = useState(false);
  const [mobilenumber, setMobileNumber] = useState(false);
  const [enterthecode, setEnterTheCode] = useState(false);
  const [mobileresetpassword, setMobileResetPassword] = useState(false);
  const [forgotData, setForgotData] = useState(false);
  const [signupemailaddress, setSignUpEmailAddress] = useState(false);
  const [verificationSuccessScreen, setVerificationSuccessScreen] =
    useState(false);
  const [signUpWithPhone, setSignUpWithPhone] = useState(false);

  const location = useLocation();

  // five image
  const [data, setData] = useState([]);

  useEffect(() => {
    // Simulate a delay of 1000 milliseconds (1 second)
    const delay = 1000;

    // Start loading state
    setIsSectionsLoading(true);

    // Fetch data after the delay
    const fetchData = async () => {
      try {
        const response = await axios.get(`${baseURL1}/v1/adds/sections?language=${strings._language}`);

        // Simulate a delay before setting the data
        setTimeout(() => {
          setData(response.data);
          setIsSectionsLoading(false);
        }, delay);
      } catch (error) {
        setIsSectionsLoading(false); // Set loading to false in case of an error
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [strings._language]);


  const [logo, setLogo] = useState("");
  function handleImage(e) {
    setLogo(e.target.files[logo]);
  }

  const navigate = useNavigate();

  const [openTab, setOpenTab] = useState(1);

  const [name, setName] = useState("");
  const [role, setRole] = useState("individual");

  const [companyName, setCompanyName] = useState("");
  const [email, setEmail] = useState("");
  const [taxNumber, setTaxNumber] = useState("");
  const [phone, setPhone] = useState("");
  const [officePhone, setOfficePhone] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [address, setAddress] = useState("");
  const [termsAccept, setTermsAccept] = useState("");

  const handlesubmit = (e) => {
    e.preventDefault();

    if (!termsAccept) {
      toast.error("Please accept terms & conditions");
    } else {
      setSignUpLoader(true);
      axios
        .post(`${baseURL1}/v1/user/email-signup`, {
          name,
          role,
          companyName,
          email,
          taxNumber,
          phone,
          officePhone,
          logo,
          password,
          confirmPassword,
          address,
          termsAccept: "true",
        })
        .then((response) => {
          toast.success(`${strings.signUpSuccess}`);
          setSignUpLoader(false);
          setPhoneLoginOtpScreen(false);
          setForgotEmailOtpScreen(false);
          setSignUpWithPhone(false);
          setSignUpEmailAddress(false);
          setForgotData(false);
          setEmailSignUpOtpScreen(true);
          setEmailSignInForm(false);
          setSignUpForm(false);
          setSignInWithNumber(false);
          setSignInWithLogin(false);
          setSignInVerify(false);
          setRecoverWithEmail(false);
          setResetPassword(false);
          setPasswordUpdatedSuccess(false);
          setPhoneSignUpVerifyCodeScreen(false);
          setMobileNumber(false);
          setMobileResetPassword(false);
        })
        .catch((error) => {
          toast.error(error.response.data.error);
          setSignUpLoader(false);
        });
    }
  };

  const handleUserVerification = (e) => {
    e.preventDefault();

    setUserVerificationLoader(true);
    axios
      .post(`${baseURL1}/v1/user/verify`, {
        code: emailVerificationCode,
      })
      .then((response) => {
        toast.success(`${strings.userVerified}`);
        setUserVerificationLoader(true);
        setPhoneLoginOtpScreen(false);
        setForgotEmailOtpScreen(false);
        setSignUpWithPhone(false);
        setSignUpEmailAddress(false);
        setForgotData(false);
        setEmailSignUpOtpScreen(false);
        setEmailSignInForm(true);
        setSignUpForm(false);
        setSignInWithNumber(false);
        setSignInWithLogin(false);
        setSignInVerify(false);
        setRecoverWithEmail(false);
        setResetPassword(false);
        setPasswordUpdatedSuccess(false);
        setPhoneSignUpVerifyCodeScreen(false);
        setMobileNumber(false);
        setMobileResetPassword(false);
      })
      .catch((error) => {
        toast.error(error.response.data.error);
        setUserVerificationLoader(false);
      });
  };

  const callPhoneSignUpApi = (uId) => {
    axios
      .post(`${baseURL1}/v1/user/phone-signup`, {
        name: name,
        phone: phone,
        role: "individual",
        termsAccept: true,
        firebaseUid: uId,
      })
      .then((res) => {
        Cookies.set("token", res.data.token, { expires: 7 });
        Cookies.set("userDetails", JSON.stringify(res.data.user), {
          expires: 7,
        });
        setTimeout(() => {
          window.location.reload();
        }, 500);
      })
      .catch((err) => toast.error("Failed to Login"));
  };

  const callPhoneSignInApi = (uId) => {
    axios
      .post(`${baseURL1}/v1/user/phone-login`, {
        firebaseUid: uId,
      })
      .then((res) => {
        Cookies.set("token", res.data.token, { expires: 7 });
        Cookies.set("userDetails", JSON.stringify(res.data.user), {
          expires: 7,
        });
        setTimeout(() => {
          window.location.reload();
        }, 500);
      })
      .catch((err) => toast.error("Failed to Login"));
  };

  const auth = getAuth(app);
  auth.languageCode = "en";

  function onCaptchVerify() {
    if (auth) {
      if (!window.recaptchaVerifier) {
        window.recaptchaVerifier = new RecaptchaVerifier(
          auth,
          "sign-in-button",
          {
            size: "invisible",
            callback: (response) => {
              onSignup();
            },
            "expired-callback": () => { },
          }
        );
      }
    }
  }

  function onSignup() {
    setLoading(true);
    onCaptchVerify();

    const appVerifier = window.recaptchaVerifier;

    signInWithPhoneNumber(auth, "+" + phone, appVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        setLoading(false);
        setPhoneLoginOtpScreen(false);
        setPhoneSignUpVerifyCodeScreen(true);
        setSignUpWithPhone(false);
        setSignUpEmailAddress(false);
        setForgotData(false);
        setEmailSignUpOtpScreen(false);
        setEmailSignInForm(false);
        setSignUpForm(false);
        setSignInWithNumber(false);
        setSignInWithLogin(false);
        setSignInVerify(false);
        setRecoverWithEmail(false);
        setResetPassword(false);
        setPasswordUpdatedSuccess(false);
        setMobileNumber(false);
        setMobileResetPassword(false);
        toast.success(`${strings.otpSent}`);
      })
      .catch((error) => {
        setLoading(false);
      });
  }

  function verifySignUp() {
    setUserVerificationLoader(true);
    window.confirmationResult
      .confirm(otp)
      .then(async (res) => {
        callPhoneSignUpApi(res.user.uid);
        setLoading(false);
        setUserVerificationLoader(false);
        setVerificationSuccessScreen(false);
        setPhoneLoginOtpScreen(false);
        setSignUpEmailAddress(false);
        setForgotData(false);
        setEmailSignUpOtpScreen(false);
        setEmailSignInForm(false);
        setSignUpForm(false);
        setSignInWithNumber(true);
        setSignInWithLogin(false);
        setSignInVerify(false);
        setRecoverWithEmail(false);
        setResetPassword(false);
        setPasswordUpdatedSuccess(false);
        setPhoneSignUpVerifyCodeScreen(false);
        setMobileNumber(false);
        setMobileResetPassword(false);
        setSignUpWithPhone(false);
      })
      .catch((err) => {
        setUserVerificationLoader(false);
      });
  }

  function verifyLogin() {
    setUserVerificationLoader(true);
    window.confirmationResult
      .confirm(otp)
      .then(async (res) => {
        callPhoneSignInApi(res.user.uid);
        setUserVerificationLoader(false);
        setVerificationSuccessScreen(true);
        setPhoneLoginOtpScreen(false);
        setSignUpEmailAddress(false);
        setForgotData(false);
        setEmailSignUpOtpScreen(false);
        setEmailSignInForm(false);
        setSignUpForm(false);
        setSignInWithNumber(false);
        setSignInWithLogin(false);
        setSignInVerify(false);
        setRecoverWithEmail(false);
        setResetPassword(false);
        setPasswordUpdatedSuccess(false);
        setPhoneSignUpVerifyCodeScreen(false);
        setMobileNumber(false);
        setMobileResetPassword(false);
        setSignUpWithPhone(false);
      })
      .catch((err) => {
        setUserVerificationLoader(false);
      });
  }

  function handleForgotApi(event) {
    event.preventDefault();
    if (!forgotEmailEntry) {
      toast.error(`${strings.emailIsRequired}`);
      return;
    } else {
      setForgotEmailLoader(true);
      axios
        .get(`${baseURL1}/v1/user/forget-password?email=${forgotEmailEntry}`)
        .then((response) => {
          setForgotEmailLoader(false);
          setForgotEmailOtpScreen(true);
          setSignInVerify(false);
          setSignUpForm(false);
          setForgotData(false);
          setEmailSignUpOtpScreen(false);
          setEmailSignInForm(false);
          setSignInWithNumber(false);
          setSignInWithLogin(false);
          setRecoverWithEmail(false);
          setResetPassword(false);
          setPasswordUpdatedSuccess(false);
          setPhoneSignUpVerifyCodeScreen(false);
          setMobileNumber(false);
          setMobileResetPassword(false);
          setPhoneLoginOtpScreen(false);
          setSignUpWithPhone(false);
        })
        .catch((error) => {
          setForgotEmailLoader(false);
        });
    }
  }

  const resendEmail = async () => {
    try {
      setResendEmailLoader(true)


      const response = await axios.get(`${baseURL1}/v1/user/forget-password?email=${forgotEmailEntry}`)
      toast.success("Email Resent!")
      setResendEmailLoader(false)
      setTimer(30)
    } catch {
      toast.error('Failed')
      setResendEmailLoader(false)
    }
  }

  function handleEmailReset(event) {
    event.preventDefault();
    setEmailResetLoader(true);
    axios
      .get(`${baseURL1}/v1/user/verify?code=${emailResetCode}`)
      .then((response) => {
        setEmailResetLoader(false);
        setPassword('')
        setConfirmPassword('')
        setResetPassword(true);
        setForgotEmailOtpScreen(false);
        setSignInVerify(false);
        setSignUpForm(false);
        setForgotData(false);
        setEmailSignUpOtpScreen(false);
        setEmailSignInForm(false);
        setSignInWithNumber(false);
        setSignInWithLogin(false);
        setRecoverWithEmail(false);
        setPasswordUpdatedSuccess(false);
        setPhoneSignUpVerifyCodeScreen(false);
        setMobileNumber(false);
        setMobileResetPassword(false);
        setPhoneLoginOtpScreen(false);
        setSignUpWithPhone(false);
      })
      .catch((error) => {
        toast.error(`${error.response.data.error}`);
        setEmailResetLoader(false);
      });
  }

  //reset password api
  function handleSubmitResetPassword() {
    setResetPasswordLoader(true);
    axios
      .post(`${baseURL1}/v1/user/reset-password`, {
        code: emailResetCode,
        password: password,
        confirmPassword: confirmPassword,
      })
      .then((response) => {
        setResetPasswordLoader(false);
        setPasswordUpdatedSuccess(true);
        setResetPassword(false);
        setForgotEmailOtpScreen(false);
        setSignInVerify(false);
        setSignUpForm(false);
        setForgotData(false);
        setEmailSignUpOtpScreen(false);
        setEmailSignInForm(false);
        setSignInWithNumber(false);
        setSignInWithLogin(false);
        setRecoverWithEmail(false);
        setMobileNumber(false);
        setMobileResetPassword(false);
        setPhoneLoginOtpScreen(false);
        setSignUpWithPhone(false);
      })
      .catch((error) => {
        toast.error(`${error.response.data.error}`);
        setResetPasswordLoader(false);
      });
  }

  function onCaptch2Verify() {
    if (auth) {
      if (!window.recaptchaVerifier) {
        window.recaptchaVerifier = new RecaptchaVerifier(
          auth,
          "login-in-button",
          {
            size: "invisible",
            callback: (response) => {
              onPhoneLogin();
            },
            "expired-callback": () => { },
          }
        );
      }
    }
  }

  function onPhoneLogin() {
    setPhoneLoginLoader(true);
    onCaptch2Verify();

    const appVerifier = window.recaptchaVerifier;

    signInWithPhoneNumber(auth, "+" + phone, appVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        setPhoneLoginLoader(false);
        setPhoneLoginOtpScreen(true);
        setSignUpWithPhone(false);
        setSignUpEmailAddress(false);
        setForgotData(false);
        setEmailSignUpOtpScreen(false);
        setEmailSignInForm(false);
        setSignUpForm(false);
        setSignInWithNumber(false);
        setSignInWithLogin(false);
        setSignInVerify(false);
        setRecoverWithEmail(false);
        setResetPassword(false);
        setPasswordUpdatedSuccess(false);
        setPhoneSignUpVerifyCodeScreen(false);
        setMobileNumber(false);
        setMobileResetPassword(false);
        toast.success(`${strings.otpSent}`);
      })
      .catch((error) => {
        setPhoneLoginLoader(false);
      });
  }

  const notifications = useSelector(state => state.notifications.notifications);


  const fetchNotifications = async () => {
    try {
      const response = await apiClient.get("/v1/user/notification")
      const notifications = response.data;

      // Dispatch the action to store notifications in Redux
      dispatch(setNotifications(notifications));
    } catch (error) {
      console.error('Failed to fetch notifications:', error);
    }
  };


  useEffect(() => {
    fetchNotifications()
  }, []);

  useEffect(() => {
    axios
      .get(`${baseURL1}/v1/adds/sections?language=${strings._language}`)
      .then((response) => {
        setBrands(response.data[0].categories);
      })
      .catch((err) => { });;
  }, [strings._language]);

  useEffect(() => {
    axios
      .get(`${baseURL1}/v1/adds/sections?language=${strings._language}`)
      .then((response) => {
        setRealEstateBrands(response.data[1].categories);
      })
      .catch((err) => { });;
  }, [strings._language]);

  useEffect(() => {
    const userDetails = Cookies.get("userDetails");
    if (userDetails) {
      const user = JSON.parse(userDetails);
      const {
        name,
        companyName,
        phone,
        email,
        country,
        city,
        logo,
        address,
        image,
        taxNumber,
        officePhone,
        gender,
      } = user;
      const filteredUser = {
        name,
        companyName,
        phone,
        email,
        country,
        city,
        logo,
        address,
        image,
        taxNumber,
        officePhone,
        gender,
      };
      setProfileData(filteredUser);
    } else {
      setProfileData("Null");
    }
  }, []);

  return (
    <div>
      <div id="recaptcha-container"></div>
      {/*Navbar Start*/}
      <Navbar collapseOnSelect expand="lg">
        <Container className="  md:h-[100px]">
          <Navbar.Brand className="col-8  flex justify-start">
            <Link to="/">
              <img src={logoImage} alt="" className="h-[180px] w-[180px]" />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav " className="col-4 ">


            <Nav className="ms-auto md:p-0 p-2  flex h-max items-center w-[100%] justify-between">

              <Link to="/">
                <button className="text-orange-400 ml-1 md:mb-0 mb-2 ">{strings.home}</button>
              </Link>

              {token === undefined ? (
                <div
                  className="border-1 p-[6px] px-[12px] rounded-full d:mb-0 mb-2 border-[#F37521] text-white bg-[#F37521] cursor-pointer whitespace-nowrap inline-block"
                  onClick={handleShow}
                >
                  {strings.login}
                </div>
              ) : (
                <>
                  {profileData !== "Null" ? (
                    <Avatar
                      alt="Travis Howard"
                      src={
                        profileData.image === "" ? profile : profileData.image
                      }
                      style={{
                        height: 60,
                        width: 60,
                      }}
                      onClick={handleMenu1Open}
                      ref={button1Ref}
                    />
                  ) : (
                    <Avatar
                      alt="Travis Howard"
                      src=""
                      style={{
                        height: 50,
                        width: 50,
                        marginTop: 7,
                      }}
                      onClick={handleMenu1Open}
                      ref={button1Ref}
                    />
                  )}
                </>
              )}
              <Menu
                anchorEl={button1Ref.current}
                open={Boolean(menu1Open)}
                onClose={handleMenu1Close}
              >
                <MenuItem
                  onClick={() => {
                    if (token === null || token === undefined) {
                      toast.warn(`${strings.loginWarn}`);
                    } else {
                      navigate("/profile");
                    }

                    handleMenu1Close();
                  }}
                >
                  {strings.myProfile}
                </MenuItem>
                <MenuItem onClick={() => {
                  navigate('/ads-management')
                  handleMenu1Close()
                }}>{strings.AdManagement}</MenuItem>
                <MenuItem onClick={handleMenu1Close}>
                  {strings.balance}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    if (token === null || token === undefined) {
                      toast.warn(`${strings.loginWarn}`);
                    } else {
                      navigate("/favorites");
                    }

                    handleMenu1Close();
                  }}
                >
                  {strings.fav}
                </MenuItem>
                <MenuItem onClick={handleMenu1Close}>
                  {strings.messages}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    navigate('/packages')
                    handleMenu1Close()
                  }}
                >
                  {strings.packages}
                </MenuItem>
                <MenuItem
                  onClick={handleMenu1Close}
                  className="flex justify-between"
                >
                  <div>{strings.lang}</div>
                  <div className="text-orange-400 ml-2">
                    {strings._language}
                  </div>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    if (token === null || token === undefined) {
                      toast.warn(`${strings.loginWarn}`);
                    } else {
                      navigate("/privacy-policy");
                    }

                    handleMenu1Close();
                  }}
                >
                  {strings.Priv}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    if (token === null || token === undefined) {
                      toast.warn(`${strings.loginWarn}`);
                    } else {
                      navigate("/faq");
                    }

                    handleMenu1Close();
                  }}
                >
                  {strings.faqs}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    if (token === null || token === undefined) {
                      toast.warn(`${strings.loginWarn}`);
                    } else {
                      navigate("/reportproblem");
                    }
                    handleMenu1Close();
                  }}
                >
                  {strings.reportProb}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    if (token === null || token === undefined) {
                      toast.warn(`${strings.loginWarn}`);
                    } else {
                      navigate("/aboutus");
                    }

                    handleMenu1Close();
                  }}
                >
                  {strings.about}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    if (token === undefined) {
                      handleShow();
                    } else {
                      logOutUser();
                    }
                    handleMenu1Close();
                  }}
                >
                  {token === undefined ? (
                    <>{strings.login}</>
                  ) : (
                    <>{strings.logout}</>
                  )}
                </MenuItem>
              </Menu>

              {createPage === "" || token === undefined ? (
                ""
              ) : (
                <div
                  onClick={() => {
                    if (token === null || token === undefined) {
                      toast.warn(`${strings.loginWarn}`);
                    } else {
                      navigate(`${createPage}`, {
                        state: {
                          id: id,
                        },
                      });
                    }
                  }}
                  className="border-1 p-[6px] rounded-full border-[#F37521] text-[#F37521] hover:bg-[#E5E5E5] cursor-pointer whitespace-nowrap"
                >

                  {strings.createAd}
                </div>
              )}

              <Nav.Link
                className="relative flex items-center bg-red-600"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasExample"
                aria-controls="offcanvasExample"
              >
                <span class="sr-only">Notifications</span>
                <div class="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-red-500 border-2 border-white rounded-full -right-1 dark:border-gray-900">
                  {notifications.length}
                </div>
                <img className="w-[50px] cursor-pointer mt-2 h-[50px]" src={NavbarImage1} alt="" />
              </Nav.Link>

              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleMenuClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem
                  onClick={() => {
                    // selectLanguage("en")
                    handleLanguageChange("en");
                    handleMenuClose();
                  }}
                  style={{
                    backgroundColor:
                      Cookies.get("language") === "en" ? "#F37521" : "",
                    color: Cookies.get("language") === "en" ? "#fff" : "",
                  }}
                >
                  <img src={EnglishFlag} alt="Eng Flag" className="h-[20px] mr-2" />
                  English
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    // selectLanguage("ru")
                    handleLanguageChange("ru");
                    handleMenuClose();
                  }}
                  style={{
                    backgroundColor:
                      Cookies.get("language") === "ru" ? "#F37521" : "",
                    color: Cookies.get("language") === "ru" ? "#fff" : "",
                  }}
                >
                  <img src={RussianFlag} alt="Russian Flag" className="h-[20px] mr-2" />
                  Русский
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    // selectLanguage("uz")
                    handleLanguageChange("uz");
                    handleMenuClose();
                  }}
                  style={{
                    backgroundColor:
                      Cookies.get("language") === "uz" ? "#F37521" : "",
                    color: Cookies.get("language") === "uz" ? "#fff" : "",
                  }}
                >
                  <img src={uzbekFlag} alt="Uz Flag" className="h-[20px] mr-2" />
                  O'zbek
                </MenuItem>
              </Menu>
              {!disableLanguage && <img
                className="w-[50px] cursor-pointer h-[50px]"
                src={NavbarImage2}
                alt=""
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
              />}

            </Nav>

          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Modal
        size="md"
        style={{ marginTop: "50px" }}
        show={show}
        onHide={handleClose}
      >
        {emailSignInForm ? (
          <>
            <Login />

            <div style={{ background: "#F4F5F9" }}>
              <div className="d-flex text-orange-600 justify-content-center mt-4 cursor-pointer">
                <div
                  onClick={() => {
                    setForgotData(true);
                    setEmailSignInForm(false);
                    setSignInWithNumber(false);
                    setSignInWithLogin(false);
                    setSignInVerify(false);
                    setRecoverWithEmail(false);
                    setResetPassword(false);
                    setPasswordUpdatedSuccess(false);
                    setPhoneSignUpVerifyCodeScreen(false);
                    setMobileNumber(false);
                    setMobileResetPassword(false);
                    setPhoneLoginOtpScreen(false);
                    setSignUpWithPhone(false);
                  }}
                >
                  {strings.forgotPassword}
                </div>
              </div>

              <div className="flex justify-center">
                <button
                  className=" p-3 w-72 mt-3 bg-orange-600 text-white rounded-full"
                  onClick={() => {
                    setSignInWithNumber(true);
                    setSignUpForm(false);
                    setForgotData(false);
                    setEmailSignUpOtpScreen(false);
                    setEmailSignInForm(false);

                    setSignInWithLogin(false);
                    setSignInVerify(false);
                    setRecoverWithEmail(false);
                    setResetPassword(false);
                    setPasswordUpdatedSuccess(false);
                    setPhoneSignUpVerifyCodeScreen(false);
                    setMobileNumber(false);
                    setMobileResetPassword(false);
                    setPhoneLoginOtpScreen(false);
                    setSignUpWithPhone(false);
                  }}
                >
                  {strings.withNumber}
                </button>
              </div>

              <div className="flex justify-center mb-3">
                <span>
                  {strings.dontHave}{" "}
                  <button
                    className="ms-2 text-orange-600"
                    onClick={() => {
                      setSignUpForm(true);
                      setSignInWithNumber(false);
                      setForgotData(false);
                      setEmailSignUpOtpScreen(false);
                      setEmailSignInForm(false);
                      setSignInWithLogin(false);
                      setSignInVerify(false);
                      setRecoverWithEmail(false);
                      setResetPassword(false);
                      setPasswordUpdatedSuccess(false);
                      setPhoneSignUpVerifyCodeScreen(false);
                      setMobileNumber(false);
                      setMobileResetPassword(false);
                      setPhoneLoginOtpScreen(false);
                      setSignUpWithPhone(false);
                    }}
                  >
                    {strings.signUp}
                  </button>{" "}
                </span>
              </div>
            </div>
          </>
        ) : forgotData ? (
          <>
            <div
              className="container rounded-lg"
              style={{ background: "#F4F5F9" }}
            >
              <div className="p-4">
                <h5 className="text-center font-bold">
                  {strings.forgotPassword}
                </h5>
                <p className="text-center pt-2">
                  {strings.pleaseSelectRecoveryMethod}
                </p>
              </div>

              <div className="flex justify-center">
                <img className="w-16" src={Lock2} alt="" />
              </div>

              <div className=" flex justify-center">
                <button
                  className=" h-16 w-72 mt-5 bg-orange-600 text-white rounded-full"
                  onClick={() => {
                    setRecoverWithEmail(true);
                    setForgotData(false);
                    setEmailSignUpOtpScreen(false);
                    setEmailSignInForm(false);
                    setSignUpForm(false);
                    setSignInWithNumber(false);
                    setSignInWithLogin(false);
                    setSignInVerify(false);
                    setResetPassword(false);
                    setPasswordUpdatedSuccess(false);
                    setPhoneSignUpVerifyCodeScreen(false);
                    setMobileNumber(false);
                    setMobileResetPassword(false);
                    setPhoneLoginOtpScreen(false);
                    setSignUpWithPhone(false);
                  }}
                >
                  {strings.emailAddress}
                </button>
              </div>

              <div className=" flex justify-center">
                <button
                  className=" h-16 w-72 mt-4 bg-orange-600 text-white rounded-full mb-5"
                  onClick={() => {
                    setMobileNumber(true);
                    setSignUpForm(false);
                    setForgotData(false);
                    setEmailSignUpOtpScreen(false);
                    setEmailSignInForm(false);
                    setSignInWithNumber(false);
                    setSignInWithLogin(false);
                    setSignInVerify(false);
                    setRecoverWithEmail(false);
                    setResetPassword(false);
                    setPasswordUpdatedSuccess(false);
                    setPhoneSignUpVerifyCodeScreen(false);
                    setMobileResetPassword(false);
                    setPhoneLoginOtpScreen(false);
                    setSignUpWithPhone(false);
                  }}
                >
                  {strings.mobileNumber}
                </button>
              </div>
            </div>
          </>
        ) : signinwithnumber ? (
          <>
            <div
              className="container rounded-lg"
              style={{ background: "#F4F5F9" }}
            >
              <h4 className="fw-bold  mt-4 text-center">{strings.login}</h4>
              <p className="mt-4">{strings.pleaseSignIn}</p>
              <div className="flex justify-center mt-5">
                <div className="flex w-80 justify-center bg-white rounded-full">
                  <img className=" py-2 mt-2 w-6" src={phoneIcon} alt="" />
                  <CountrySelect
                    className="w-16 p-1 outline-none "
                    labels={en}
                    value={country}
                    onChange={(e) => handlePhoneCode(e)}
                  />

                  <input
                    className="outline-none"
                    onChange={(e) => handlePhoneChange(e)}
                    value={phone}
                  />
                </div>
              </div>
              <div className="flex justify-center">
                <button
                  id="login-in-button"
                  className="p-3 h-15 w-80 mt-4 bg-orange-600 rounded-full text-white"
                  onClick={onPhoneLogin}
                >
                  {phoneLoginLoader ? (
                    <>
                      <ClipLoader size={15} color="#fff" /> {strings.loading}
                    </>
                  ) : (
                    strings.login
                  )}
                </button>
              </div>

              <div className="flex justify-center mt-4">
                <button
                  className=" h-10 w-52 mt-2 bg-orange-600 text-white rounded-full mb-2"
                  onClick={() => {
                    setForgotData(false);
                    setEmailSignUpOtpScreen(false);
                    setEmailSignInForm(true);
                    setSignUpForm(false);
                    setSignInWithNumber(false);
                    setSignInWithLogin(false);
                    setSignInVerify(false);
                    setRecoverWithEmail(false);
                    setResetPassword(false);
                    setPasswordUpdatedSuccess(false);
                    setPhoneSignUpVerifyCodeScreen(false);
                    setMobileNumber(false);
                    setMobileResetPassword(false);
                    setPhoneLoginOtpScreen(false);
                    setSignUpWithPhone(false);
                  }}
                >
                  {strings.signinWithEmail}
                </button>
              </div>

              <div className="flex justify-center mt-4">
                <span>
                  {strings.dontHave}{" "}
                  <button
                    className="ps-2 mb-3 text-orange-300"
                    onClick={() => {
                      setForgotData(false);
                      setEmailSignUpOtpScreen(false);
                      setEmailSignInForm(false);
                      setSignUpForm(true);
                      setSignInWithNumber(false);
                      setSignInWithLogin(false);
                      setSignInVerify(false);
                      setRecoverWithEmail(false);
                      setResetPassword(false);
                      setPasswordUpdatedSuccess(false);
                      setPhoneSignUpVerifyCodeScreen(false);
                      setMobileNumber(false);
                      setMobileResetPassword(false);
                      setPhoneLoginOtpScreen(false);
                      setSignUpWithPhone(false);
                    }}
                  >
                    {strings.signUp}
                  </button>
                </span>
              </div>
            </div>
          </>
        ) : signinwithlogin ? (
          <>
            <div
              className="container rounded-lg"
              style={{ background: "#F4F5F9" }}
            >
              <h4 className="mt-4 text-center">{strings.enterTheCode}</h4>
              <p>{strings.codeThatReceived}</p>
              <div className="flex justify-center">
                <img
                  src={Verfiy}
                  alt=""
                  style={{ width: "180px", height: "130px" }}
                />
              </div>

              <div className="flex justify-evenly mt-5">
                <h4 className="bg-white shadow-lg w-12 h-12 pt-2 rounded-full text-center ">
                  5
                </h4>

                <h4 className="bg-white shadow-lg w-12 h-12 pt-2 rounded-full text-center ">
                  5
                </h4>

                <h4 className="bg-white shadow-lg w-12 h-12 pt-2 rounded-full text-center ">
                  5
                </h4>

                <h4 className="bg-white shadow-lg w-12 h-12 pt-2 rounded-full text-center ">
                  5
                </h4>
              </div>

              <div className="flex  justify-center mt-4">
                <button
                  className=" h-16 w-96 mt-2 bg-orange-600 text-white rounded-full mb-5"
                  onClick={() => {
                    setSignInVerify(true);
                    setSignUpForm(false);
                    setForgotData(false);
                    setEmailSignUpOtpScreen(false);
                    setEmailSignInForm(false);

                    setSignInWithNumber(false);
                    setSignInWithLogin(false);
                    setRecoverWithEmail(false);
                    setResetPassword(false);
                    setPasswordUpdatedSuccess(false);
                    setPhoneSignUpVerifyCodeScreen(false);
                    setMobileNumber(false);
                    setMobileResetPassword(false);
                    setPhoneLoginOtpScreen(false);
                    setSignUpWithPhone(false);
                  }}
                >
                  {strings.verify}
                </button>
              </div>
            </div>
          </>
        ) : signinverify ? (
          <>
            <div
              className="container rounded-lg"
              style={{ background: "#F4F5F9" }}
            >
              <h4 className="mt-4 text-center">{strings.verified}</h4>
              <p>{strings.verificationMsg}</p>
              <div className="flex justify-center mt-4">
                <img
                  src={Successfuly}
                  alt=""
                  style={{ width: "180px", height: "130px" }}
                />
              </div>
              <div className="flex justify-center mt-4">
                <Link to="/">
                  <button
                    className=" h-16 w-96 mt-2 bg-orange-600 text-white rounded-full mb-5"
                    onClick={() => {
                      setShow(false);
                      setSignUpLoader(false);
                      setPhoneLoginOtpScreen(false);
                      setSignUpWithPhone(false);
                      setSignUpEmailAddress(false);
                      setForgotData(false);
                      setEmailSignUpOtpScreen(false);
                      setEmailSignInForm(false);
                      setSignUpForm(false);
                      setSignInWithNumber(false);
                      setSignInWithLogin(false);
                      setSignInVerify(false);
                      setRecoverWithEmail(false);
                      setResetPassword(false);
                      setPasswordUpdatedSuccess(false);
                      setPhoneSignUpVerifyCodeScreen(false);
                      setMobileNumber(false);
                      setMobileResetPassword(false);
                    }}
                  >
                    {strings.continue}
                  </button>
                </Link>
              </div>
            </div>
          </>
        ) : recoverWithEmail ? (
          <>
            <div
              className="container  mx-auto rounded-lg"
              style={{ background: "#F4F5F9" }}
            >
              <div className="p-4">
                <h5 className="text-center font-bold">
                  {strings.forgotPassword}
                </h5>
                <p className="text-center pt-2">{strings.pleaseEnterEmail}</p>
              </div>

              <div className="flex justify-center">
                <img className="w-16" src={Lock} alt="" />
              </div>
              <form>
                <div className="flex flex-col items-center p-3 ">
                  <input
                    className="p-3 h-15 w-80  bg-white rounded-full   outline-orange-600 "
                    type="email"
                    value={forgotEmailEntry}
                    placeholder={strings.email}
                    onChange={(e) => setForgotEmailEntry(e.target.value)}
                  />

                  <button
                    className="bg-orange-500 w-80 mt-3  rounded-full h-15 p-3 mb-4 text-white "
                    onClick={(event) => handleForgotApi(event)}
                  >
                    {forgotEmailLoader ? (
                      <>
                        <ClipLoader size={15} color="#fff" /> {strings.loading}
                      </>
                    ) : (
                      strings.sendRequestEmail
                    )}
                  </button>
                </div>
              </form>
            </div>
          </>
        ) : forgotEmailOtpScreen ? (
          <>
            <div
              className="container  rounded-lg"
              style={{ background: "#F4F5F9" }}
            >
              <div className="p-4">
                <h5 className="text-center font-bold">
                  {strings.forgotPassword}
                </h5>
                <p className="text-center pt-2">{strings.checkEmail}</p>
              </div>

              <div className="flex justify-center">
                <img className="w-40" src={mailic} alt="" />
              </div>

              <form>
                <div className="flex justify-center mt-3">
                  <input
                    className="bg-white shadow-md rounded-full w-80  p-3   outline-orange-600"
                    type="code"
                    value={emailResetCode}
                    placeholder={strings.enterTheCode}
                    onChange={(e) => setEmailResetCode(e.target.value)}
                  />
                  <br />
                </div>

                <div className="w-full mt-2 flex justify-center">
                  <div className="text-orange-600 cursor-pointer "
                    onClick={() => {
                      if (timer <= 0) {
                        resendEmail()
                      }

                    }}
                  >
                    {
                      resendEmailLoader
                        ? <ClipLoader size={8} />
                        : `${strings.resendCode} ${timer > 0 ? `(${formatTime(timer)})` : ''}`
                    }

                  </div>
                </div>

                <div className="flex justify-center mt-4">
                  <button
                    className="bg-orange-500 w-80 rounded-full h-15 p-3 mb-4 text-white"
                    onClick={(event) => {
                      handleEmailReset(event);
                    }}
                  >
                    {emailResetLoader ? (
                      <>
                        <ClipLoader size={15} color="#fff" /> {strings.loading}
                      </>
                    ) : (
                      strings.sendRequest
                    )}
                  </button>
                </div>
              </form>
            </div>
          </>
        ) : resetpassword ? (
          <>
            <div
              className="container  rounded-lg"
              style={{ background: "#F4F5F9" }}
            >
              <div className="p-4">
                <h5 className="text-center font-bold">
                  {strings.resetpassword}
                </h5>
                <p className="text-center pt-2">
                  {strings.pleaseEnterNewPassword}
                </p>
              </div>

              <div className="flex justify-center">
                <img className="w-32" src={ResetPassword} alt="" />
              </div>

              <form>
                <div className="flex flex-col items-center">
                  <div className="flex w-80 rounded-full h-15 p-3 mb-4 shadow-md border-orange-400 border-2 outline-orange-600 pr-12">
                    <input
                      className="outline-none bg-transparent w-[85%]"
                      type={showPassword ? "text" : "password"}
                      value={password}
                      placeholder="Enter Password"
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    {showPassword ? (
                      <LockIcon
                        className="cursor-pointer"
                        onClick={() => setShowPassword(false)}
                      />
                    ) : (
                      <LockOpenIcon
                        className="cursor-pointer"
                        onClick={() => setShowPassword(true)}
                      />
                    )}
                  </div>

                  <div className="flex w-80 rounded-full h-15 p-3 mb-4 shadow-md border-orange-400 border-2 outline-orange-600 pr-12">
                    <input
                      className="outline-none bg-transparent w-[85%]"
                      type={showPassword ? "text" : "password"}
                      value={confirmPassword}
                      placeholder="ConfirmPassword"
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                    {showPassword ? (
                      <LockIcon
                        className="cursor-pointer"
                        onClick={() => setShowPassword(false)}
                      />
                    ) : (
                      <LockOpenIcon
                        className="cursor-pointer"
                        onClick={() => setShowPassword(true)}
                      />
                    )}
                  </div>



                </div>

                <div className="flex justify-center mt-4">
                  <button
                    className="bg-orange-500 w-80 rounded-full h-15 p-3 mb-4 text-white"
                    onClick={() => {
                      handleSubmitResetPassword();
                    }}
                  >
                    {resetPasswordLoader ? (
                      <>
                        <ClipLoader size={15} color="#fff" /> {strings.loading}
                      </>
                    ) : (
                      strings.resetpassword
                    )}
                  </button>
                </div>
              </form>
            </div>
          </>
        ) : passwordUpdatedSuccess ? (
          <>
            {" "}
            <div
              className="container rounded-lg"
              style={{ background: "#F4F5F9" }}
            >
              <h4 className="mt-5 text-center">{strings.resetpassword}</h4>
              <p className="mt-3">{strings.passwordUpdated}</p>
              <div className="flex justify-center mt-4">
                <img
                  className="mt-3"
                  src={PasswordUpdate}
                  alt=""
                  style={{ width: "180px", height: "130px" }}
                />
              </div>
              <div className="flex justify-center mt-4">
                <button
                  className=" h-16 w-80 mt-4 bg-orange-600 text-white rounded-full mb-5"
                  onClick={() => {
                    setForgotData(false);
                    setEmailSignUpOtpScreen(false);
                    setEmailSignInForm(true);
                    setSignUpForm(false);
                    setSignInWithNumber(false);
                    setSignInWithLogin(false);
                    setSignInVerify(false);
                    setRecoverWithEmail(false);
                    setResetPassword(false);
                    setPasswordUpdatedSuccess(false);
                    setPhoneSignUpVerifyCodeScreen(false);
                    setMobileNumber(false);
                    setMobileResetPassword(false);
                    setPhoneLoginOtpScreen(false);
                    setSignUpWithPhone(false);
                  }}
                >
                  {strings.login}
                </button>
              </div>
            </div>
          </>
        ) : mobilenumber ? (
          <>
            <div
              className="container rounded-lg"
              style={{ background: "#F4F5F9" }}
            >
              <div className="p-4">
                <h5 className="text-center font-bold">
                  {strings.forgotPassword}
                </h5>
                <p className="text-center pt-2">{strings.pleaseEnterNumber}</p>
              </div>

              <div className="flex justify-center">
                <img className="w-16" src={Lock2} alt="" />
              </div>

              <div className="flex justify-center mt-3">
                <div className="relative">
                  <img
                    className="absolute py-3  w-6  right-6 lg:right-6 md:right-6"
                    src={phone}
                    alt=""
                  />
                  <input
                    className="bg-white shadow-md rounded-full w-80  p-3 border-orange-400 border-2 outline-orange-600"
                    type="text"
                    placeholder={strings.mobileNumber}
                    onChange={(e) => {
                      setRequestedPhoneNumber(e.target.value);
                    }}
                    value={requestedPhoneNumber}
                  />
                </div>
              </div>

              <div className="flex justify-center mt-4">
                <button
                  className="bg-orange-500 w-80 shadow-md rounded-full h-15 p-3 mb-4 text-white"
                  onClick={(event) => sendPhoneCodeRequest(event)}
                >
                  {strings.sendRequest}
                </button>
              </div>
            </div>
          </>
        ) : enterthecode ? (
          <>
            <div
              className="container rounded-lg"
              style={{ background: "#F4F5F9" }}
            >
              <h4 className="mt-4 text-center">{strings.enterTheCode}</h4>
              <p>{strings.codeThatReceived}</p>
              <div className="flex justify-center">
                <img
                  src={Verfiy}
                  alt=""
                  style={{ width: "180px", height: "130px" }}
                />
              </div>

              <div className="otp-container">
                {inputRefs.map((ref, index) => (
                  <input
                    key={index}
                    ref={ref}
                    type="text"
                    maxLength="1"
                    onInput={(event) => handleInput(event, index)}
                    onPaste={handlePaste}
                  />
                ))}
              </div>

              <div className="flex  justify-center mt-4">
                <button
                  className=" h-16 w-96 mt-2 bg-orange-600 text-white rounded-full mb-5"
                  onClick={(event) => handlePhoneVerificationCode(event)}
                >
                  {strings.verify}
                </button>
              </div>
            </div>
          </>
        ) : mobileresetpassword ? (
          <>
            {" "}
            <div
              className="container rounded-lg"
              style={{ background: "#F4F5F9" }}
            >
              <div className="p-4">
                <h5 className="text-center font-bold">
                  {strings.resetpassword}
                </h5>
                <p className="text-center pt-2">
                  {strings.pleaseEnterNewPassword}
                </p>
              </div>

              <div className="flex justify-center">
                <img className="w-32" src={ResetPassword} alt="" />
              </div>

              <div className="flex justify-center mt-3">
                <div className="relative">
                  {isLocked ? (
                    <LockIcon
                      className="absolute mt-3 w-6 right-6 lg:right-6 md:right-6 cursor-pointer"
                      style={{
                        color: "grey",
                      }}
                      onClick={() => {
                        setIsLocked(false);
                        setShowPassword(true);
                      }}
                    />
                  ) : (
                    <LockOpenIcon
                      className="absolute mt-3 w-6 right-6 lg:right-6 md:right-6 cursor-pointer"
                      style={{
                        color: "grey",
                      }}
                      onClick={() => {
                        setIsLocked(true);
                        setShowPassword(false);
                      }}
                    />
                  )}
                  <input
                    className="bg-white shadow-md rounded-full w-80  p-3 border-orange-400 border-2 outline-orange-600"
                    type={showPassword ? "text" : "password"}
                    placeholder={strings.pass}
                    id="inputlogin"
                  />
                </div>
              </div>

              <div className="flex justify-center mt-3">
                <div className="relative">
                  {isLocked ? (
                    <LockIcon
                      className="absolute mt-3 w-6 right-6 lg:right-6 md:right-6 cursor-pointer"
                      style={{
                        color: "grey",
                      }}
                      onClick={() => {
                        setIsLocked(false);
                        setShowPassword(true);
                      }}
                    />
                  ) : (
                    <LockOpenIcon
                      className="absolute mt-3 w-6 right-6 lg:right-6 md:right-6 cursor-pointer"
                      style={{
                        color: "grey",
                      }}
                      onClick={() => {
                        setIsLocked(true);
                        setShowPassword(false);
                      }}
                    />
                  )}
                  <input
                    className="bg-white shadow-md rounded-full w-80  p-3 border-orange-400 border-2 outline-orange-600"
                    type="text"
                    placeholder={strings.confirmPass}
                    id="inputlogin"
                  />
                </div>
              </div>

              <div className="flex justify-center mt-4">
                <button
                  className="bg-orange-500 w-80 rounded-full h-15 p-3 mb-4 text-white"
                  onClick={() => {
                    setPasswordUpdatedSuccess(true);
                    setResetPassword(false);
                    setForgotEmailOtpScreen(false);
                    setSignInVerify(false);
                    setSignUpForm(true);
                    setForgotData(false);
                    setEmailSignUpOtpScreen(false);
                    setEmailSignInForm(false);
                    setSignInWithNumber(false);
                    setSignInWithLogin(false);
                    setRecoverWithEmail(false);
                    setMobileNumber(false);
                    setMobileResetPassword(false);
                    setPhoneLoginOtpScreen(false);
                    setSignUpWithPhone(false);
                  }}
                >
                  {strings.resetpassword}
                </button>
              </div>
            </div>
          </>
        ) : signUpForm ? (
          <>
            <div
              className="container rounded-lg"
              style={{ background: "#F4F5F9" }}
            >
              <h6 className="text-center mt-4">{strings.createAcc}</h6>
              <p className="text-center ">{strings.pleaseSelectAccMethod}</p>

              <div className="flex justify-center">
                <button
                  className=" h-12 w-72 mt-5 bg-orange-600 text-white rounded-full"
                  onClick={() => {
                    setSignUpEmailAddress(true);
                    setSignUpWithPhone(false);
                    setForgotData(false);
                    setEmailSignUpOtpScreen(false);
                    setEmailSignInForm(false);
                    setSignUpForm(false);
                    setSignInWithNumber(false);
                    setSignInWithLogin(false);
                    setSignInVerify(false);
                    setRecoverWithEmail(false);
                    setResetPassword(false);
                    setPasswordUpdatedSuccess(false);
                    setPhoneSignUpVerifyCodeScreen(false);
                    setMobileNumber(false);
                    setMobileResetPassword(false);
                    setPhoneLoginOtpScreen(false);
                    setSignUpWithPhone(false);
                  }}
                >
                  {strings.emailAddress}
                </button>
              </div>

              <div className="flex justify-center">
                <button
                  className=" h-12 w-72 mt-5 bg-orange-600 text-white rounded-full"
                  onClick={() => {
                    setSignUpEmailAddress(false);
                    setSignUpWithPhone(true);
                    setForgotData(false);
                    setEmailSignUpOtpScreen(false);
                    setEmailSignInForm(false);
                    setSignUpForm(false);
                    setSignInWithNumber(false);
                    setSignInWithLogin(false);
                    setSignInVerify(false);
                    setRecoverWithEmail(false);
                    setResetPassword(false);
                    setPasswordUpdatedSuccess(false);
                    setPhoneSignUpVerifyCodeScreen(false);
                    setMobileNumber(false);
                    setMobileResetPassword(false);
                    setPhoneLoginOtpScreen(false);
                  }}
                >
                  {strings.mobileNumber}
                </button>
              </div>

              <div className="flex justify-center mt-2">
                <span>
                  {strings.alreadyHave}{" "}
                  <button
                    className="ms-3 text-orange-400 mb-4"
                    onClick={() => {
                      setForgotData(false);
                      setEmailSignUpOtpScreen(false);
                      setEmailSignInForm(true);
                      setSignUpForm(false);
                      setSignInWithNumber(false);
                      setSignInWithLogin(false);
                      setSignInVerify(false);
                      setRecoverWithEmail(false);
                      setResetPassword(false);
                      setPasswordUpdatedSuccess(false);
                      setPhoneSignUpVerifyCodeScreen(false);
                      setMobileNumber(false);
                      setMobileResetPassword(false);
                      setPhoneLoginOtpScreen(false);
                      setSignUpWithPhone(false);
                    }}
                  >
                    {strings.signIn}
                  </button>
                </span>
              </div>
            </div>
          </>
        ) : signupemailaddress ? (
          <>
            <div
              className="container mx-auto rounded-lg"
              style={{ background: "#F4F5F9" }}
            >
              <h4 className="fw-bold  text-center mt-5">{strings.createAcc}</h4>
              <p>{strings.pleaseCreateTo}</p>

              <div className="max-w-3xl mx-auto px-8 sm:px-0">
                <div className="sm:w-7/12 sm:mx-auto">
                  <div className="flex justify-center">
                    <div className="flex justify-between rounded-full bg-white w-max">
                      <button
                        className={`p-3  rounded-full focus:outline-none ${role === "individual"
                          ? "bg-orange-600 text-white"
                          : "text-black"
                          }`}
                        onClick={() => {
                          setOpenTab(1);
                          setRole("individual");
                        }}
                      >
                        {strings.ind}
                      </button>
                      <button
                        className={` p-3  rounded-full shadow-full focus:outline-none ${role === "corporate"
                          ? "bg-orange-600 text-white"
                          : " text-black"
                          }`}
                        onClick={() => {
                          setOpenTab(2);
                          setRole("corporate");
                        }}
                      >
                        {strings.corporate}
                      </button>
                    </div>
                  </div>

                  <div className="mt-6 ">
                    <div className={openTab === 1 ? "block" : "hidden"}>
                      <div className="flex justify-center">
                        <div className="relative">
                          <img
                            className="absolute py-2 mt-1 w-6  right-6 lg:right-6 md:right-6"
                            src={profile}
                            alt=""
                          />
                          <input
                            className="bg-white shadow-md rounded-full w-80  p-3  outline-orange-600"
                            type="text"
                            value={name}
                            placeholder={strings.name}
                            onChange={(e) => setName(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="flex justify-center mt-4">
                        <div className="relative">
                          <img
                            className="absolute py-3 mt-1 w-6  right-6 lg:right-6 md:right-6"
                            src={phoneIcon}
                            alt=""
                          />
                          <input
                            className="bg-white shadow-md rounded-full w-80  p-3  outline-orange-600"
                            type="text"
                            name="phone"
                            value={phone}
                            placeholder={strings.phone}
                            onChange={(e) => setPhone(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="flex justify-center mt-4">
                        <div className="relative">
                          <img
                            className="absolute py-3 mt-1 w-6  right-6 lg:right-6 md:right-6"
                            src={loginmessage}
                            alt=""
                          />
                          <input
                            className="bg-white shadow-md rounded-full w-80  p-3  outline-orange-600"
                            type="text"
                            name="email"
                            value={email}
                            placeholder={strings.email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="flex justify-center mt-4">
                        <div className="relative">
                          {isLocked ? (
                            <LockIcon
                              className="absolute mt-3 w-6 right-6 lg:right-6 md:right-6 cursor-pointer"
                              style={{
                                color: "grey",
                              }}
                              onClick={() => {
                                setIsLocked(false);
                                setShowPassword(true);
                              }}
                            />
                          ) : (
                            <LockOpenIcon
                              className="absolute mt-3 w-6 right-6 lg:right-6 md:right-6 cursor-pointer"
                              style={{
                                color: "grey",
                              }}
                              onClick={() => {
                                setIsLocked(true);
                                setShowPassword(false);
                              }}
                            />
                          )}
                          <input
                            className="bg-white shadow-md rounded-full w-80  p-3  outline-orange-600"
                            type={showPassword ? "text" : "password"}
                            name="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder={strings.pass}
                          />
                        </div>
                      </div>
                      <div className="flex justify-center mt-4">
                        <div className="relative">
                          {isLocked ? (
                            <LockIcon
                              className="absolute mt-3 w-6 right-6 lg:right-6 md:right-6 cursor-pointer"
                              style={{
                                color: "grey",
                              }}
                              onClick={() => {
                                setIsLocked(false);
                                setShowPassword(true);
                              }}
                            />
                          ) : (
                            <LockOpenIcon
                              className="absolute mt-3 w-6 right-6 lg:right-6 md:right-6 cursor-pointer"
                              style={{
                                color: "grey",
                              }}
                              onClick={() => {
                                setIsLocked(true);
                                setShowPassword(false);
                              }}
                            />
                          )}
                          <input
                            className="bg-white shadow-md rounded-full w-80  p-3  outline-orange-600"
                            type={showPassword ? "text" : "password"}
                            name="confirmPassword"
                            value={confirmPassword}
                            placeholder={strings.confirmPass}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className="checkbox mt-3 flex w-max -ml-10">
                        <div>
                          <input
                            type="checkbox"
                            value={termsAccept}
                            onClick={() => setTermsAccept(true)}
                          />
                        </div>
                        <div className="text-sm ml-1 text-center">
                          {strings.readAndAgree}
                          <span className="text-orange-600"> {strings.termsOfUse}</span>
                          &nbsp; {strings.and}
                          <br /><span className="text-orange-600"> {strings.privacyPolicy}</span>


                        </div>
                      </div>

                      <div className="flex justify-center mt-4">
                        <button>
                          <button
                            className="mt-3 bg-orange-600 text-white shadow-md rounded-full w-80  p-3"
                            onClick={handlesubmit}
                          >
                            {signUpLoader ? (
                              <>
                                <ClipLoader size={15} color="#fff" />{" "}
                                {strings.loading}
                              </>
                            ) : (
                              strings.signUp
                            )}
                          </button>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className={openTab === 2 ? "block" : "hidden"}>
                    <div className="flex justify-center">
                      <div className="relative">
                        <img
                          className="absolute py-2 mt-2 w-6  right-6 lg:right-6 md:right-6"
                          src={profile}
                          alt=""
                        />
                        <input
                          className="bg-white shadow-md rounded-full w-80  p-3  outline-orange-600"
                          type="text"
                          name="companyName"
                          value={companyName}
                          placeholder={strings.companyName}
                          onChange={(e) => setCompanyName(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="flex justify-center mt-4">
                      <div className="relative">
                        <img
                          className="absolute py-2 mt-1 w-6  right-6 lg:right-6 md:right-6"
                          src={textnumber}
                          alt=""
                        />

                        <input
                          className="bg-white shadow-md rounded-full w-80  p-3  outline-orange-600"
                          type="text"
                          name="taxNumber"
                          value={taxNumber}
                          placeholder={strings.taxNumber}
                          onChange={(e) => setTaxNumber(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="flex justify-center mt-4">
                      <div className="relative">
                        <img
                          className="absolute py-3 mt-1 w-6  right-6 lg:right-6 md:right-6"
                          src={loginmessage}
                          alt=""
                        />
                        <input
                          className="bg-white shadow-md rounded-full w-80  p-3  outline-orange-600"
                          type="text"
                          name="email"
                          value={email}
                          placeholder={strings.emailAddress}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="flex justify-center mt-4">
                      <div className="relative">
                        <img
                          className="absolute py-2 mt-2 w-5  right-6 lg:right-6 md:right-6"
                          src={phoneIcon}
                          alt=""
                        />

                        <input
                          className="bg-white shadow-md rounded-full w-80  p-3  outline-orange-600"
                          type="text"
                          name="phone"
                          value={phone}
                          placeholder={strings.mobileNumber}
                          onChange={(e) => setPhone(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="flex justify-center mt-4">
                      <div className="relative">
                        <img
                          className="absolute py-2 mt-2 w-5  right-6 lg:right-6 md:right-6"
                          src={phoneIcon}
                          alt=""
                        />

                        <input
                          className="bg-white shadow-md rounded-full w-80  p-3  outline-orange-600"
                          type="text"
                          name="officePhone"
                          value={officePhone}
                          placeholder={strings.officeNumber}
                          onChange={(e) => setOfficePhone(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="flex justify-center mt-4">
                      <div className="relative">
                        <img
                          className="absolute py-2 mt-2 w-5  right-6 lg:right-6 md:right-6"
                          src={locationIcon}
                          alt=""
                        />

                        <input
                          className="bg-white shadow-md rounded-full w-80  p-3  outline-orange-600"
                          type="text"
                          name="address"
                          value={address}
                          placeholder={strings.officeAddress}
                          onChange={(e) => setAddress(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="flex justify-center mt-4">
                      <div className="relative">
                        {isLocked ? (
                          <LockIcon
                            className="absolute mt-3 w-6 right-6 lg:right-6 md:right-6 cursor-pointer"
                            style={{
                              color: "grey",
                            }}
                            onClick={() => {
                              setIsLocked(false);
                              setShowPassword(true);
                            }}
                          />
                        ) : (
                          <LockOpenIcon
                            className="absolute mt-3 w-6 right-6 lg:right-6 md:right-6 cursor-pointer"
                            style={{
                              color: "grey",
                            }}
                            onClick={() => {
                              setIsLocked(true);
                              setShowPassword(false);
                            }}
                          />
                        )}
                        <input
                          className="bg-white shadow-md rounded-full w-80  p-3  outline-orange-600"
                          type={showPassword ? "text" : "password"}
                          name="password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          placeholder={strings.pass}
                        />
                      </div>
                    </div>
                    <div className="flex justify-center mt-4">
                      <div className="relative">
                        {isLocked ? (
                          <LockIcon
                            className="absolute mt-3 w-6 right-6 lg:right-6 md:right-6 cursor-pointer"
                            style={{
                              color: "grey",
                            }}
                            onClick={() => {
                              setIsLocked(false);
                              setShowPassword(true);
                            }}
                          />
                        ) : (
                          <LockOpenIcon
                            className="absolute mt-3 w-6 right-6 lg:right-6 md:right-6 cursor-pointer"
                            style={{
                              color: "grey",
                            }}
                            onClick={() => {
                              setIsLocked(true);
                              setShowPassword(false);
                            }}
                          />
                        )}
                        <input
                          className="bg-white shadow-md rounded-full w-80  p-3  outline-orange-600"
                          type={showPassword ? "text" : "password"}
                          name="confirmPassword"
                          value={confirmPassword}
                          placeholder={strings.confirmPass}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                      </div>
                    </div>

                    {/*  */}

                    <div className="mt-4 flex justify-center shadow-md p-3">
                      <div>
                        <input
                          type="file"
                          name="file"
                          className="d-none"
                          ref={hiddenFileInput}
                          onChange={(e) => {
                            handleLogoUpload(e.target.files[0]);
                          }}
                        />
                        <div className="flex justify-center">
                          {strings.addLogo}
                        </div>

                        <div>
                          <button
                            onClick={handleFileClick}
                            className="addimages"
                          >
                            Upload
                          </button>
                        </div>
                      </div>
                    </div>

                    {/*  */}

                    <div className="checkbox mt-3 flex w-max -ml-10">
                      <div>
                        <input
                          type="checkbox"
                          value={termsAccept}
                          onClick={() => setTermsAccept(true)}
                        />
                      </div>
                      <div className="text-sm ml-1 text-center">
                        {strings.readAndAgree}
                        <span className="text-orange-600"> {strings.termsOfUse}</span>
                        &nbsp; {strings.and}
                        <br /><span className="text-orange-600"> {strings.privacyPolicy}</span>


                      </div>
                    </div>

                    <div className="flex justify-center mt-4">
                      <button
                        className="mt-3 bg-orange-600 text-white shadow-md rounded-full w-80  p-3"
                        onClick={handlesubmit}
                      >
                        {strings.signUp}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex justify-center mt-2">
              <span>
                Already Have an Account?
                <button
                  className="ms-3 text-orange-400 mb-4"
                  onClick={() => {
                    setPhoneLoginOtpScreen(false);
                    setForgotData(false);
                    setEmailSignUpOtpScreen(false);
                    setEmailSignInForm(true);
                    setSignUpForm(false);
                    setSignInWithNumber(false);
                    setSignInWithLogin(false);
                    setSignInVerify(false);
                    setRecoverWithEmail(false);
                    setResetPassword(false);
                    setPasswordUpdatedSuccess(false);
                    setPhoneSignUpVerifyCodeScreen(false);
                    setMobileNumber(false);
                    setMobileResetPassword(false);
                    setVerificationSuccessScreen(false);
                    setSignUpWithPhone(false);
                  }}
                >
                  {strings.signIn}
                </button>
              </span>
            </div>
          </>
        ) : phoneLoginOtpScreen ? (
          // Phone Login Verification
          <div
            className="container rounded-lg"
            style={{ background: "#F4F5F9" }}
          >
            <h4 className="mt-5 text-center">{strings.enterTheCode}</h4>
            <p>{strings.codeThatReceived}</p>
            <div className="flex justify-center">
              <img
                src={Verfiy}
                alt=""
                style={{ width: "180px", height: "130px" }}
              />
            </div>

            <div className="flex justify-center mt-5">
              <input
                className="bg-white shadow-md rounded-full w-80  p-3"
                type="text"
                placeholder={strings.enterTheCode}
                id="code"
                onChange={(e) => setOtp(e.target.value)}
                value={otp}
              />
            </div>

            <div className="flex justify-center mt-4">
              <button
                className=" h-16 w-80 mt-2 bg-orange-600 text-white rounded-full mb-5"
                onClick={() => {
                  verifyLogin();
                }}
              >
                {userVerificationLoader ? (
                  <>
                    <ClipLoader size={12} color="#fff" /> {strings.loading}
                  </>
                ) : (
                  strings.verify
                )}
              </button>
            </div>
          </div>
        ) : verificationSuccessScreen ? (
          <>
            <div
              className="container rounded-lg"
              style={{ background: "#F4F5F9" }}
            >
              <h4 className="mt-4 text-center">{strings.verified}</h4>
              <p>{strings.verificationMsg}</p>
              <div className="flex justify-center mt-4">
                <img
                  src={Successfuly}
                  alt=""
                  style={{ width: "180px", height: "130px" }}
                />
              </div>
              <div className="flex justify-center mt-4">
                <button
                  className=" h-16 w-80 mt-2 bg-orange-600 text-white rounded-full mb-5"
                  onClick={() => setShow(false)}
                >
                  {strings.continue}
                </button>
              </div>
            </div>
          </>
        ) : signUpWithPhone ? (
          <>
            <div
              className="container mx-auto rounded-lg"
              style={{ background: "#F4F5F9" }}
            >
              <h4 className="fw-bold  text-center mt-5">{strings.createAcc}</h4>
              <p>{strings.pleaseCreateTo}</p>

              <div className="max-w-3xl mx-auto px-8 sm:px-0">
                <div className="sm:w-7/12 sm:mx-auto">
                  <div className="mt-6 ">
                    <div className={openTab === 1 ? "block" : "hidden"}>
                      <div className="flex justify-center ">
                        <div className="relative">
                          <img
                            className="absolute py-2 mt-1 w-6  right-6 lg:right-6 md:right-6"
                            src={profile}
                            alt=""
                          />
                          <input
                            className="bg-white shadow-md rounded-full w-80  p-3  outline-orange-600"
                            type="text"
                            value={name}
                            placeholder={strings.name}
                            onChange={(e) => setName(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="flex w-80 justify-center bg-white rounded-full mt-3 -ml-5 p-2 shadow-md">
                        <CountrySelect
                          className="w-16 outline-none "
                          labels={en}
                          value={country}
                          onChange={(e) => handlePhoneCode(e)}
                        />

                        <input
                          className="outline-none"
                          onChange={(e) => handlePhoneChange(e)}
                          value={phone}
                        />
                        <img className=" mt-2 w-6" src={phoneIcon} alt="" />
                      </div>

                      <div className="checkbox mt-3 flex w-max -ml-10">
                        <div>
                          <input
                            type="checkbox"
                            value={termsAccept}
                            onChange={(e) => setTermsAccept(e.target.value)}
                          />
                        </div>
                        <div className="text-sm ml-1 text-center">
                          {strings.readAndAgree}
                          <span className="text-orange-600"> {strings.termsOfUse}</span>
                          &nbsp; {strings.and}
                          <br /><span className="text-orange-600"> {strings.privacyPolicy}</span>


                        </div>
                      </div>

                      <div className="flex justify-center mt-4">
                        <button>
                          <button
                            className="mt-3 bg-orange-600 text-white shadow-md rounded-full w-80  p-3"
                            id="sign-in-button"
                            onClick={onSignup}
                          >
                            {loading ? (
                              <>
                                <ClipLoader size={15} color="#fff" />{" "}
                                {strings.loading}
                              </>
                            ) : (
                              strings.signUp
                            )}
                          </button>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className={openTab === 2 ? "block" : "hidden"}>
                    <div className="flex justify-center">
                      <div className="relative">
                        <img
                          className="absolute py-2 mt-2 w-6  right-6 lg:right-6 md:right-6"
                          src={profile}
                          alt=""
                        />
                        <input
                          className="bg-white shadow-md rounded-full w-80  p-3  outline-orange-600"
                          type="text"
                          name="companyName"
                          value={companyName}
                          placeholder={strings.companyName}
                          onChange={(e) => setCompanyName(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="flex justify-center mt-4">
                      <div className="relative">
                        <img
                          className="absolute py-2 mt-1 w-6  right-6 lg:right-6 md:right-6"
                          src={textnumber}
                          alt=""
                        />

                        <input
                          className="bg-white shadow-md rounded-full w-80  p-3  outline-orange-600"
                          type="text"
                          name="taxNumber"
                          value={taxNumber}
                          placeholder={strings.taxNumber}
                          onChange={(e) => setTaxNumber(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="flex justify-center mt-4">
                      <div className="relative">
                        <img
                          className="absolute py-3 mt-1 w-6  right-6 lg:right-6 md:right-6"
                          src={loginmessage}
                          alt=""
                        />
                        <input
                          className="bg-white shadow-md rounded-full w-80  p-3  outline-orange-600"
                          type="text"
                          name="email"
                          value={email}
                          placeholder={strings.emailAddress}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="flex justify-center mt-4">
                      <div className="relative">
                        <img
                          className="absolute py-2 mt-2 w-5  right-6 lg:right-6 md:right-6"
                          src={phone}
                          alt=""
                        />

                        <input
                          className="bg-white shadow-md rounded-full w-80  p-3  outline-orange-600"
                          type="text"
                          name="phone"
                          value={phone}
                          placeholder={strings.mobileNumber}
                          onChange={(e) => setPhone(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="flex justify-center mt-4">
                      <div className="relative">
                        <img
                          className="absolute py-2 mt-2 w-5  right-6 lg:right-6 md:right-6"
                          src={phone}
                          alt=""
                        />

                        <input
                          className="bg-white shadow-md rounded-full w-80  p-3  outline-orange-600"
                          type="text"
                          name="officePhone"
                          value={officePhone}
                          placeholder={strings.officeNumber}
                          onChange={(e) => setOfficePhone(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="flex justify-center mt-4">
                      <div className="relative">
                        <img
                          className="absolute py-2 mt-2 w-5  right-6 lg:right-6 md:right-6"
                          src={location}
                          alt=""
                        />

                        <input
                          className="bg-white shadow-md rounded-full w-80  p-3  outline-orange-600"
                          type="text"
                          name="address"
                          value={address}
                          placeholder={strings.officeAddress}
                          onChange={(e) => setAddress(e.target.value)}
                        />
                      </div>
                    </div>

                    {/*  */}

                    <div className="mt-4 flex justify-center shadow-md p-3">
                      <div>
                        <input
                          type="file"
                          name="file"
                          onChange={handleImage}
                          className="d-none"
                          ref={hiddenFileInput}
                        />
                        <div className="flex justify-center">
                          {strings.addLogo}
                        </div>

                        <div>
                          <button
                            onClick={handleFileClick}
                            className="addimages"
                          >
                            Upload
                          </button>
                        </div>
                      </div>
                    </div>

                    {/*  */}

                    <div className="checkbox mt-3 flex w-max -ml-10">
                      <div>
                        <input
                          type="checkbox"
                          value={termsAccept}
                          onChange={(e) => setTermsAccept(e.target.value)}
                        />
                      </div>
                      <div className="text-sm ml-1 text-center">
                        {strings.readAndAgree}
                        <span className="text-orange-600"> {strings.termsOfUse}</span>
                        &nbsp; {strings.and}
                        <br /><span className="text-orange-600"> {strings.privacyPolicy}</span>


                      </div>
                    </div>

                    <div className="flex justify-center mt-4">
                      <button
                        className="mt-3 bg-orange-600 text-white shadow-md rounded-full w-80  p-3"
                        onClick={() => handlesubmit()}
                      >
                        {signUpLoader ? (
                          <>
                            <ClipLoader size={15} color="#fff" />{" "}
                            {strings.loading}
                          </>
                        ) : (
                          strings.signUp
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex justify-center mt-2">
              <span>
                Already Have an Account?
                <button
                  className="ms-3 text-orange-400 mb-4"
                  onClick={() => {
                    setPhoneLoginOtpScreen(false);
                    setForgotData(false);
                    setEmailSignUpOtpScreen(false);
                    setEmailSignInForm(true);
                    setSignUpForm(false);
                    setSignInWithNumber(false);
                    setSignInWithLogin(false);
                    setSignInVerify(false);
                    setRecoverWithEmail(false);
                    setResetPassword(false);
                    setPasswordUpdatedSuccess(false);
                    setPhoneSignUpVerifyCodeScreen(false);
                    setMobileNumber(false);
                    setMobileResetPassword(false);
                    setVerificationSuccessScreen(false);
                    setSignUpWithPhone(false);
                  }}
                >
                  {strings.signIn}
                </button>
              </span>
            </div>
          </>
        ) : phoneSignUpVerifyCodeScreen ? (
          <div
            className="container rounded-lg"
            style={{ background: "#F4F5F9" }}
          >
            <h4 className="mt-5 text-center">{strings.enterTheCode}</h4>
            <p>{strings.codeThatReceived}</p>
            <div className="flex justify-center">
              <img
                src={Verfiy}
                alt=""
                style={{ width: "180px", height: "130px" }}
              />
            </div>

            <div className="flex justify-center mt-5">
              <input
                className="bg-white shadow-md rounded-full w-80  p-3"
                type="text"
                placeholder={strings.enterTheCode}
                id="code"
                onChange={(e) => setOtp(e.target.value)}
                value={otp}
              />
            </div>

            <div className="flex justify-center mt-4">
              <button
                className=" h-16 w-80 mt-2 bg-orange-600 text-white rounded-full mb-5"
                onClick={() => {
                  verifySignUp();
                }}
              >
                {userVerificationLoader ? (
                  <>
                    <ClipLoader size={12} color="#fff" /> {strings.loading}
                  </>
                ) : (
                  strings.verify
                )}
              </button>
            </div>
          </div>
        ) : emailSignUpOtpScreen ? (
          <div
            className="container  rounded-lg"
            style={{ background: "#F4F5F9" }}
          >
            <div className="p-4">
              <h5 className="text-center font-bold">{strings.emailVerify}</h5>
              <p className="text-center pt-2">{strings.checkEmail}</p>
            </div>

            <div className="flex justify-center">
              <img className="w-40" src={Verify} alt="" />
            </div>

            <form>
              <div className="flex justify-center mt-3">
                <input
                  className="bg-white shadow-md rounded-full w-80  p-3   outline-orange-600"
                  type="code"
                  value={emailVerificationCode}
                  placeholder={strings.enterTheCode}
                  onChange={(e) => setEmailVerificationCode(e.target.value)}
                />
                <br />
              </div>

              <div className="flex justify-center mt-4">
                <button
                  className="bg-orange-500 w-80 rounded-full h-15 p-3 mb-4 text-white"
                  onClick={(event) => {
                    handleUserVerification(event);
                  }}
                >
                  {userVerificationLoader ? (
                    <>
                      <ClipLoader size={15} color="#fff" /> {strings.loading}
                    </>
                  ) : (
                    strings.sendRequest
                  )}
                </button>
              </div>
            </form>
          </div>
        ) : (
          ""
        )}
      </Modal>
      <div
        class="offcanvas offcanvas-end"
        tabindex="-1"
        id="offcanvasExample"
        aria-labelledby="offcanvasExampleLabel"
      >
        <div class="offcanvas-header">
          <h5 class="offcanvas-title" id="offcanvasExampleLabel">
            {strings.notif}
          </h5>
          <button
            type="button"
            class="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div class="offcanvas-body">
          {notifications.map((data, index) => (
            <div
              key={index}
              className={`p-2 ${index % 2 === 0 ? "bg-gray-300" : ""}`}
            >
              <p style={{ fontWeight: "bold" }}>
                {index + 1}. {data.title}
              </p>
              <p>{data.message}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AppBar;
